import React from 'react'

import Container from 'components/container'
import RingsIcon from 'components/icons/rings'
import SpermWithStarsIcon from 'components/icons/sperm-with-stars'
import FlaskIcon from 'components/icons/flask'
import RabbitsIcon from 'components/icons/rabbits'
import SpermYinYangIcon from 'components/icons/sperm-ying-yang'
import StarSpermIcon from 'components/icons/star-sperm'
import { Section, Heading, List, LinkWrapper } from './styled'
import { PrimaryLink } from 'components/links'
import Urls from 'common/urls'

const Methodology = () => (
  <Section>
    <Container>
      <Heading>What we analyze in your sperm sample.</Heading>

      <List>
        <li>
          <h3>Volume</h3>
          <h4>Milliliters (mL) of your entire sample</h4>
          <p>
            Volume refers to the amount of semen in your sample (measured in
            mL). Volume can fluctuate quite a bit depending on when you last
            ejaculated.
          </p>
          <FlaskIcon />
        </li>
        <li>
          <h3>Concentration</h3>
          <h4>How many million sperm per mL</h4>
          <p>
            Sperm concentration is the number of sperm per mL in a semen sample.
          </p>
          <SpermWithStarsIcon />
        </li>
        <li>
          <h3>Count</h3>
          <h4>How many sperm are in your sample</h4>
          <p>
            Sperm count is the total number of sperm in the entire sample. To
            determine the sperm count, sperm concentration is multiplied by the
            total volume of the sample.
          </p>
          <RabbitsIcon />
        </li>
        <li>
          <h3>Motility</h3>
          <h4>How your sperm are swimming</h4>
          <p>
            Sperm motility refers to the percentage of sperm that are swimming
            in a sample. This is important in fertility because sperm need to
            swim to reach and fertilize an egg.
          </p>
          <RingsIcon />
        </li>
        <li>
          <h3>Total Motile Count</h3>
          <h4>How many sperm in your sample are swimming</h4>
          <p>
            This is regarded as the <strong>most important factor</strong> for
            male fertility- it is the result of multiplying sample volume,
            concentration, and motility.
          </p>
          <StarSpermIcon />
        </li>
        <li>
          <h3>Morphology</h3>
          <h4>How your sperm are shaped</h4>
          <p>
            Sperm morphology refers to the shape of your sperm. A typical sample
            only has about 4%-20% of normal shaped sperm cells. Shocking? Well,
            you’re making 1,500 per second, so there’s bound to be some errors!
          </p>
          <SpermYinYangIcon />
        </li>
      </List>
      <LinkWrapper>
        <PrimaryLink to={Urls.science}>Learn about our science</PrimaryLink>
      </LinkWrapper>
    </Container>
  </Section>
)

export default Methodology
