import React, { useEffect } from 'react'
import Layout from 'components/layout'
import ProductSEO from 'components/seo/product'
import TestimonialsCarousel from 'components/testimonials-carousel'
import StickyFooter from 'components/product-sticky-footer'
import Hero from './hero'
import ParallaxContent from './parallax-content'
import Methodology from './methodology'
import FAQ from './faq'
import SaveYourSperm from './save-your-sperm'
import Privacy from './privacy'
import WhyGetAnalysis from './why-get-a-semen-analysis'
import Comparison from 'components/comparison'
import useKitProduct from 'hooks/use-kit-product'
import { formatCurrency } from 'utils/format'
import Urls from 'common/urls'
import ReferencesFootnote from 'components/references-footnote'

const IndexPage = () => {
  const product = useKitProduct()
  useEffect(() => {
    dataLayer.push({
      event: 'eec.detail',
      ecommerce: {
        detail: {
          products: [
            {
              id: product.id,
              name: product.attributes.name,
              price: formatCurrency(product.price),
            },
          ],
        },
      },
      // @ts-ignore
      branch: window.ab_branch, // branch is defined on the window in our SEO component
    })
  }, [product.attributes.name, product.id, product.price, product.product.name])

  const title = 'Semen Analysis Kit | Doctor Reviewed Male Fertility Report'
  const seoContent = {
    title: title,
    description:
      'The Fellow® at-home sperm analysis kit gives you personalized fertility results. Best-in-class Home Male Fertility Test. Doctor Approved. Privacy and Security Assured.',
    url: Urls.kit,
    name: title,
    price: formatCurrency(product.price),
    productImageUrl:
      'https://fellow-images.s3-us-west-2.amazonaws.com/Fellow_Semen_Analysis.png',
  }

  const tableContent = {
    checks: [
      {
        headline: 'Affordable',
        text: `$${formatCurrency(product.price, false)}`,
      },
      {
        headline: 'Convenient',
        text: 'Provide your sample in the comfort of your home',
      },
      {
        headline: 'Your call',
        text:
          'Get your results as soon as our CLIA-certified lab approves them',
      },
    ],
    crosses: [
      {
        headline: 'Average cost',
        text: 'Upwards of $500 or more',
      },
      {
        headline: 'Awkward',
        text: 'Provide your sample awkwardly in a clinic',
      },
      {
        headline: 'Dependent',
        text: 'Wait for your doctor to send you your results',
      },
    ],
  }

  const references = [
    {
      symbol: '¶',
      content:
        'The law and science around assisted reproduction are unique to each state and are evolving. We recommend that you consult with your physician prior to utilizing this service to ensure it meets your future needs.',
    },
    {
      symbol: '§',
      content:
        'Samplaski et al. Development and validation of a novel mail-in semen analysis system and the correlation between one hour and delayed semen analysis testing; Fertil Steril. 2021;115(4):922-929',
    },
  ]

  return (
    <Layout headerPosition="fixed">
      <ProductSEO
        title={seoContent.title}
        description={seoContent.description}
        url={seoContent.url}
        name={seoContent.name}
        price={seoContent.price}
        productImageUrl={seoContent.productImageUrl}
      />
      <Hero />
      <WhyGetAnalysis />
      <ParallaxContent />
      <Methodology />
      <SaveYourSperm />
      <Comparison
        heading="There's no comparison."
        tableContent={tableContent}
      />
      <TestimonialsCarousel dark showCustomers showCTA />
      <Privacy />
      <FAQ />
      <ReferencesFootnote references={references} />
      <StickyFooter />
    </Layout>
  )
}

export default IndexPage
